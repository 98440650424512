<template>
  <div>
      <base-header class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
          <span class="mask   opacity-8" :style="'background: linear-gradient(87deg, ' + colorsto[1] + ' 0, ' + colorsto[2] + ' 100%) !important;'"></span>
      </base-header>
      <div class="container-fluid mt--7">
          <div class="row">
              <div class="col">
                  <div class="card shadow">
                      <div class="card-header border-0">
                          <div class="row align-items-center">
                              <div class="col"><h3 class="mb-0">Presmerovania</h3></div>
                              <div class="col text-right">
                               <a-upload
                                                      name="import"
                                                      :multiple="false"
                                                      :customRequest="importNode"
                                                      :headers="head"
                                                      :showUploadList="false">
                                                 <span class="btn text-default btn-sm btn-icon btn-neutral">
                                                     <span class="btn-inner--icon" style="margin-right:5px"><i class="fal fa-file-import"></i></span>Import</span>
                                              </a-upload>
                                <base-button type="primary" size="sm" @click="editRedirect = {};redirectModal = true">Pridať presmerovanie</base-button>
                              </div>
                          </div>
                      </div>
                    <a-table :columns="columns" :rowKey="record => record.id" :dataSource="data" :pagination="pagination" :loading="loading" class="table-flush">
                              <template slot="editable" slot-scope="text, record">
                                 <div class="editable-cell">
                                      <div v-if="editable === 'seotitle' + record.id" class="editable-cell-input-wrapper">
                                          <a-input :value="text" @change="(e) => handleChange(e,record.id,'seotitle')" @pressEnter="check(record.id,'seotitle')" /><a-icon
                                              type="check"
                                              class="editable-cell-icon-check"
                                              @click="check(record.id,'seotitle')"
                                      />
                                      </div>
                                      <div v-else class="editable-cell-text-wrapper">
                                          {{ text || ' ' }}
                                          <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'seotitle')" />
                                      </div>
                                  </div>
                              </template>

                              <template slot="sendurl" slot-scope="text">
                                   <a  target="_blank" :href="vars.site_url + '/' + text">{{text}}</a>
                              </template>
                              <template slot="editableUrl" slot-scope="text, record">
                                   <div class="editable-cell">
                                      <div v-if="editable === 'url' + record.id" class="editable-cell-input-wrapper">
                                          <a-input :value="text" @change="(e) => handleChange(e,record.id,'url')" @pressEnter="check(record.id,'url')" /><a-icon
                                              type="check"
                                              class="editable-cell-icon-check"
                                              @click="check(record.id,'url')"
                                      />
                                      </div>
                                      <div v-else class="editable-cell-text-wrapper">
                                          {{ text || ' ' }}
                                          <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'url')" />
                                      </div>
                                  </div>
                              </template>
                              <template slot="editableDescription" slot-scope="text, record">
                                  <div class="editable-cell">
                                      <div v-if="editable === 'seodescription' + record.id" class="editable-cell-input-wrapper">
                                          <a-input :value="text" @change="(e) => handleChange(e,record.id,'seodescription')" @pressEnter="check(record.id,'seodescription')" /><a-icon
                                              type="check"
                                              class="editable-cell-icon-check"
                                              @click="check(record.id,'seodescription')"
                                      />
                                      </div>
                                      <div v-else class="editable-cell-text-wrapper">
                                          {{ text || ' ' }}
                                          <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'seodescription')" />
                                      </div>
                                  </div>
                              </template>
                              <template slot="editableKeywords" slot-scope="text, record">
                                  <div class="editable-cell">
                                      <div v-if="editable === 'seokeywords' + record.id" class="editable-cell-input-wrapper">
                                          <a-input :value="text" @change="(e) => handleChange(e,record.id,'seokeywords')" @pressEnter="check(record.id,'seokeywords')" /><a-icon
                                              type="check"
                                              class="editable-cell-icon-check"
                                              @click="check(record.id,'seokeywords')"
                                      />
                                      </div>
                                      <div v-else class="editable-cell-text-wrapper">
                                          {{ text || ' ' }}
                                          <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'seokeywords')" />
                                      </div>
                                  </div>
                              </template>


                              <template slot="date" slot-scope="detailed">
                                  {{formatDateN(detailed)}}
                              </template>
                              <template slot="trans" slot-scope="translations,record">
                                  <div class="trans" :key="lang + record.entity_id" v-for="(trans,lang) in translations">
                                      <router-link :to="'/edit-content/' + trans.entity_id" v-if="trans.lang" class="translation-button m-1" :href="'/edit-content/' + trans.entity_id">
                                          <base-button type="secondary" size="sm" :title="trans.value"  class="add-button edit-button"  icon="fal fa-check">{{lang}}</base-button>
                                      </router-link>
                                      <base-button v-else type="primary" size="sm"  class="translation-button m-1" @click="handleSubmit(record.entity_id,lang)" icon="far fa-plus">{{lang}}</base-button>
                                  </div>
                              </template>
                              <template slot="name" slot-scope="user">
                                  <span class="btn text-default btn-icon btn-secondary btn-sm"  @click="editItem(user)" ><span class="btn-inner--icon" style="margin-right:5px"><i class="fad fa-pencil-alt"></i></span>Upraviť</span>
                              </template>
                          </a-table>
                  </div>
              </div>
          </div>
      </div>
      <a-modal title="Editácia presmerovania" centered v-model="redirectModal" @ok="handleOk" okText="Uložiť" cancelText="Zrušiť">
          <div class="additional-setup">

              <a-form-item
                      :label-col="formItemLayout.labelCol"
                      :wrapper-col="formItemLayout.wrapperCol"
                      label="Presmerovanie z"
              > <a-input type="text" v-model="editRedirect.urlfrom"></a-input>
              </a-form-item>
              <a-form-item
                      :label-col="formItemLayout.labelCol"
                      :wrapper-col="formItemLayout.wrapperCol"
                      label="Presmerovať na"
              >  <a-input type="text" v-model="editRedirect.urlto"></a-input>
              </a-form-item>


          </div>
      </a-modal>



  </div>
</template>
<script>

  const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 12 },
  };
  const formTailLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8, offset: 4 },
  };

  import {dataService} from "../_services";
  import {Popconfirm} from "ant-design-vue"

  import Vue from "vue"
  import FormItem from 'ant-design-vue'

  import moment from 'moment'
  import 'moment/locale/sk';

  import {authHeader} from "../_helpers";
import {getURL} from "../api";
  Vue.use(FormItem);

  var locale = {
      "lang": {
          "placeholder": "Vybrať dátum",
          "rangePlaceholder": ["Termín od", "do"],
          "today": "Dnes",
          "now": "Teraz",
          "backToToday": "Späť na dnes",
          "ok": "Ok",
          "clear": "Vymazať",
          "month": "Mesiac",
          "year": "Rok",
          "timeSelect": "Vybrať čas",
          "dateSelect": "Vybrať dátum",
          "monthSelect": "Vybrať mesiac",
          "yearSelect": "Vybrať rok",
          "decadeSelect": "Vybrať dekádu",
          "yearFormat": "YYYY",
          "dateFormat": "M/D/YYYY",
          "dayFormat": "D",
          "dateTimeFormat": "M/D/YYYY HH:mm:ss",
          "monthFormat": "MMMM",
          "monthBeforeYear": true,
          "previousMonth": "Predchádzajúci mesiac (PageUp)",
          "nextMonth": "Nasledujúci mesiac (PageDown)",
          "previousYear": "Minulý rok (Control + left)",
          "nextYear": "Nasledujúci rok (Control + right)",
          "previousDecade": "Minulá dekáda",
          "nextDecade": "Nasledujúca dekáda",
          "previousCentury": "Minulé storočie",
          "nextCentury": "Nasledujúce storoťie"
      },
      "timePickerLocale": {
          "placeholder": "Vybrať čas"
      },
      "dateFormat": "DD.MM.YYYY",
      "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
      "weekFormat": "YYYY-wo",
      "monthFormat": "YYYY-MM"
  }

  export default {
      name: "skipass",
      props: ["id"],
      components: {
          "a-popconfirm": Popconfirm
      },
      data() {
          return {
              data: [],
              ctt: [],
              langs:[],
              vars:{},
              colorsto: JSON.parse(localStorage.colors),
              loading: false,
              filteredInfo: null,
              componentKey:0,
              original:'',
              translation:{},
              inputLang: null,
              translations:[],
              url: getURL(),
              head: authHeader(),
              editRedirect: {},
              addLimit: false,
              rdateFormat: 'YYYY/MM/DD',
              formItemLayout,
              redirectModal: false,
              formTailLayout,
              pagination: {
                  hideOnSinglePage: true,
                  pageSize: 100, // default number of pages per page
                  showSizeChanger: true, // display can change the number of pages per page
                  pageSizeOptions: ['100', '200', '300', '400'], // number of pages per option
                  showTotal: total => `Položiek: ${total}`, // show total
                  showSizeChange: (current, pageSize) => {  this.pageSize = pageSize; }, // update display when changing the number of pages per page
              },
              skipass: {prices:{}},
              locale,
              types: [
                  {"value": '0', "label": 'Dospelý'},
                  {"value": '1', "label": 'Junior / senior'},
                  {"value": '2', "label": 'Dieťa'},
              ],
              addTerm: false,
              sortedInfo: null,
          };
      },
      computed: {
          columns() {
              let { sortedInfo, filteredInfo } = this;
              sortedInfo = sortedInfo || {};
              filteredInfo = filteredInfo || {};
              const columns = [
                  {
                      title: 'Presmerovať',
                      dataIndex: 'urlfrom',
                      sorter: true,
                      scopedSlots: {customRender: 'sendurl'},
                      width: '45%',
                  },
                  {
                      title: 'na adresu',
                      dataIndex: 'urlto',
                  },
                  {
                      align: 'right',
                      scopedSlots: {customRender: 'name'},
                  }
              ];
              return columns;     },
      },
      mounted() {
          this.fetch();
dataService.axiosFetch("variable/site_url").then(results => {
                  this.vars = results;
              })

          dataService.axiosFetch("contenttypes").then(results => {
              this.contenttypes = results;

              results.forEach(obj => {
                  var iid = obj.id;
                  var vval = obj.title;
                  this.ctt.push({"text" : vval,"value" : iid});


              });
          });

          dataService.axiosFetch('languages').then(results => {
              results.forEach(obj => {
                  if(obj.def === "1") this.translation.lang = obj.short; else this.translation.translang = obj.short;  this.langs.push({"value" : obj.short , "label" : obj.title});
              });
          });
      },
      methods: {
               importNode(e)  {
      let formData = new FormData();
        formData.append('upload', e.file);
     var pa = this;
              this.axios.post(this.url.API_URL + '/importredirects',
                  formData,
                  {
                      headers: authHeader(true)
                  }
              ).then(function (data) {
                  this.fetch();
              });

              },
          openNew: function(e) {
              this.addTerm = true;

              this.translation = e;

          },
          moment,
          formatDateN: function(dt){
              return moment(dt,'YYYY-MM-DD').format('D.M.YYYY')
          },
          confirm(e,q) {

              console.log(e,q);
              dataService.axiosDelete('translation', q).then(results => {
                  if (results) {
               this.translations.splice(e, 1);
                      this.componentKey = this.componentKey + 1;
                  }
              });
          },
          handleLimit()
          {
              dataService.axiosPost('addskipasslimit', { 'skipass' : this.skipass}).then(results => {
                  this.fetch();
                  this.addLimit = false;
              });
          },
          handleOk(){

              dataService.axiosPost('editredirect', this.editRedirect).then(results => {
                  this.fetch();
                  this.redirectModal = false;
              });
          },
          cancel() {
          },  editItem(item)
          {
              this.editRedirect = item;
              this.redirectModal = true;
          },
          handleChange(pagination, filters, sorter) {
              this.filteredInfo = filters;
              this.sortedInfo = sorter;
          },
          clearFilters() {
              this.filteredInfo = null;
          },
          editing(w)
          {

              this.skipass.prices = this.data[w];
              this.skipass.daterange = [moment(w,this.rdateFormat),moment(w,this.rdateFormat)];

              this.addTerm = true;
          },
          fetchcttitle(ct)
          {
              let tcc = this.contenttypes.find(x => x.slug === ct);
              return tcc.title;
          },
          fetch() {
              this.loading = true;
              dataService.axiosFetch("redirects").then(results => {
              this.data = results;
                  this.loading = false;
              });


          }

      },
  };
</script>

<style>
  .prices-row label
  {width:100px;}
  .prices-row
  {display:inline-block;}
  .priceInput
  {border:1px solid #bdd1e4;}
  .price-input
  {text-align:center;
  }
  .skipass-prices td{
      min-width:150px;
  }
  .skipass-prices tbody th{
      min-width:250px;
      font-weight:100;
  }
  .price-input strong
  {margin-bottom:5px;display:block;}
  .price-input
  {
      margin-left:10px; width:50px;display:inline-block;
  }
  ul.ant-pagination {
      margin-top: 0;
      margin-bottom: 1em;
  }

  .ant-pagination a {
      color: #1890ff;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      -webkit-text-decoration-skip: objects;
  }

  .ant-pagination a:focus {
      text-decoration: underline;
      -webkit-text-decoration-skip: ink;
      text-decoration-skip-ink: auto;
  }

  .ant-pagination a:hover {
      color: #40a9ff;
  }

  .ant-pagination a:active {
      color: #096dd9;
  }

  .ant-pagination a:active, a:hover {
      text-decoration: none;
      outline: 0;
  }

  .ant-pagination a {
      -ms-touch-action: manipulation;
      touch-action: manipulation;
  }

  .ant-table-pagination.ant-pagination {
      margin-bottom: 40px;
      margin-right: 20px;
  }

  .ant-pagination5-item {
      border: 0;
  }

  .ant-table {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      margin-bottom: 20px;
  }

  .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
      -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      border: 0;
  }

  .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;
      margin: 0 3px;
      border-radius: 50% !important;
      width: 36px;
      height: 36px;
      font-size: 0.875rem;
  }

  .ant-pagination5-prev .ant-pagination5-item-link, .ant-pagination5-next .ant-pagination5-item-link {
      width: 36px;
      height: 36px;
      padding: 0;
      margin: 0 3px;
      font-size: 12px;
  }

  .ant-pagination5-item.ant-pagination5-item-active a {
      z-index: 1;
      color: #fff;
      background-color: #5e72e4;
      border-color: #5e72e4;
  }

  .ant-pagination5-item-link.ant-pagination5-disabled a {
      color: #8898aa;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6;
  }

</style>
